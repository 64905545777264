import { useState } from "react";

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }


    return {
        isOpen, onClose, onOpen
    }

}

const DescriereModal = ({isOpen, onOpen, onClose, currentGroup}) => {

    if (!isOpen) {
        return (<></>)
    }
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{currentGroup.descriere}</p>
                <button onClick={onClose} className="buton">Close</button>
            </div>
            
        </div>
    )
}

export default DescriereModal;