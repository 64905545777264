
import Login from "./Pages/Login";
import Cookies from "universal-cookie";
import Navbar from "./Components/Navbar";
import Main from "./Main";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Trupe from "./Pages/Trupe";
import { useEffect, useState } from "react";
import axios from "axios";
import NeSiscot from "./Pages/NeSiscot";
import NavbarNeSiscot from "./Components/NavbarNeSiscot";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials=true;
//local
// axios.defaults.baseURL = 'http://localhost:1235/api/';

function App() {
  const cookies = new Cookies();

  let user = cookies.get("user");
  // console.log(userFromCookie);

  if (user == undefined)
    return (
      <div>
        <Login />
      </div>
    );
  else if (user.isSiscot) {
    return (
      <>
        <Navbar />
        <Main />
      </>
    );
  }
  else {
    return (
      <>
        <NavbarNeSiscot />
        <NeSiscot />
      </>
    )
  }

}

export default App;
