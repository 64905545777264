import React from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import Dashboard from "./Pages/Dashboard";
import Trupe from "./Pages/Trupe";
import UpdateTrupa from "./Pages/UpdateTrupa";
import ConfirmDelete from "./Components/ConfirmDelete";

function Main() {
  const cookies = new Cookies();

  let user = cookies.get("user");
  // console.log(user.isAdmin)

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/trupe" element={<Trupe isAdmin={user.isAdmin} />} />
      <Route path="/update/:id" element={<UpdateTrupa />} />
      <Route path="/confirm/:id" element={<ConfirmDelete />} />
    </Routes>
  );
}

export default Main;
