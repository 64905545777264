import { useNavigate, useParams } from "react-router-dom"
import { deleteTrupaById, getTrupaById } from "../Api/api";
import { useEffect, useState } from "react";

const ConfirmDelete = () => {

    let {id} = useParams();

    const [trupa, setTrupa] = useState({
        numeTrupa: "",
        nrMembri: 0,
        nrMembriMinori: 0,
        localitate: "",
        numePiesa: "",
        autorPiesa: "",
        gen: "",
        regie: "",
        descriere: "",
        durataSpectacol: "",
        timpMontareDecor: 0,
        timpDemontareDecor: 0,
        cerinteDecor: "",
        linkFilmare: "",
        linkScenariu: "",
        observatii: "",
        numeReprezentant: "",
        prenumeReprezentant: "",
        numarTelefon: "",
        email: "",
        nivelStudii: "",
        categorie: "",
        nevoieCazare: false,
        nevoieMancare: false,
        nevoiSuplimentare: "",
        undeAuzit: "",
        asteptariFestival: ""
    });

    let initiereTrupa = async (id) => {
        let trupaLocala = await getTrupaById(id)
        setTrupa(trupaLocala.data.message);
    }

    useEffect(() => {
        initiereTrupa(id);
    }, [])

    const navigate = useNavigate();

    const btnDa = async () => {

        try {
            await deleteTrupaById(id);
          } catch (error) {
            console.error('Error deleting trupa:', error);
          }

        navigate("/trupe")
    }

    const btnNu = () => {
        navigate("/trupe")
    }

    return (
        <div className="page-trupe resp">
            <h1 className="h1-confirm">Sigur doriti stergerea trupei {trupa.numeTrupa}?</h1>
            <button onClick={btnDa} className="buton">DA</button>
            <button onClick={btnNu} className="buton">NU</button>
        </div>
    )
}

export default ConfirmDelete;