import { useEffect, useState } from "react";
import { getTrupaById, updateTrupaById } from "../Api/api";
import { useNavigate, useParams } from "react-router-dom";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateTrupa = () => {

    let {id} = useParams();

    const navigate = useNavigate();

    const [trupa, setTrupa] = useState({
        numeTrupa: "",
        nrMembri: 0,
        nrMembriMinori: 0,
        localitate: "",
        numePiesa: "",
        autorPiesa: "",
        gen: "",
        regie: "",
        descriere: "",
        durataSpectacol: "",
        timpMontareDecor: 0,
        timpDemontareDecor: 0,
        cerinteDecor: "",
        linkFilmare: "",
        linkScenariu: "",
        observatii: "",
        numeReprezentant: "",
        prenumeReprezentant: "",
        numarTelefon: "",
        email: "",
        nivelStudii: "",
        categorie: "",
        nevoieCazare: false,
        nevoieMancare: false,
        nevoiSuplimentare: "",
        undeAuzit: "",
        asteptariFestival: ""
    });

    let initiereTrupa = async (id) => {
        let trupaLocala = await getTrupaById(id)
        setTrupa(trupaLocala.data.message);
    }

    useEffect(() => {
        initiereTrupa(id);
    }, [])

    const updateButton = async () => {
    
        try {
            await updateTrupaById(id, trupa)
            .then(() => {
                toast.success('🦄 Trupa actualizata cu succes! 🐊', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    navigate("/trupe")
                }, 2000);
                }  
            )
            .catch((err) => {
                console.error(err);
                toast.error('🦄Eroare la datele introduse!!!🐊', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            
        } catch (error) {
            console.error('Error updating trupa:', error);
        }
    }
    

    return (
        <div className="update-trupa page-trupe">
            <label htmlFor="numeTrupa" className="label-update">Nume trupa</label>
            <input type="text" name="numeTrupa" id="numeTrupa" value={trupa.numeTrupa} onChange={(e) => setTrupa({...trupa, numeTrupa: e.target.value})} />

            <label htmlFor="nrMembri" className="label-update">Numar total membri</label>
            <input type="number" name="nrMembri" id="nrMembri" value={trupa.nrMembri} onChange={(e) => setTrupa({...trupa, nrMembri: Number(e.target.value)})} />

            <label htmlFor="nrMembriMinori" className="label-update">Numar membri minori</label>
            <input type="number" name="nrMembriMinori" id="nrMembriMinori" value={trupa.nrMembriMinori} onChange={(e) => setTrupa({...trupa, nrMembriMinori: e.target.value})} />

            <label htmlFor="localitate" className="label-update">Localitate</label>
            <input type="text" name="localitate" id="localitate" value={trupa.localitate} onChange={(e) => setTrupa({...trupa, localitate: e.target.value})} />

            <label htmlFor="numePiesa" className="label-update">Nume piesa</label>
            <input type="text" name="numePiesa" id="numePiesa" value={trupa.numePiesa} onChange={(e) => setTrupa({...trupa, numePiesa: e.target.value})} />

            <label htmlFor="autorPiesa" className="label-update">Autor piesa</label>
            <input type="text" name="autorPiesa" id="autorPiesa" value={trupa.autorPiesa} onChange={(e) => setTrupa({...trupa, autorPiesa: e.target.value})} />

            <label htmlFor="gen" className="label-update">Gen</label>
            <input type="text" name="gen" id="gen" value={trupa.gen} onChange={(e) => setTrupa({...trupa, gen: e.target.value})} />

            <label htmlFor="regie" className="label-update">Regie</label>
            <input type="text" name="regie" id="regie" value={trupa.regie} onChange={(e) => setTrupa({...trupa, regie: e.target.value})} />

            <label htmlFor="descriere" className="label-update">Descriere</label>
            <textarea name="descriere" id="descriere" value={trupa.descriere} onChange={(e) => setTrupa({...trupa, descriere: e.target.value})} />

            <label htmlFor="durataSpectacol" className="label-update">Durata spectacol (min)</label>
            <input type="number" name="durataSpectacol" id="durataSpectacol" value={trupa.durataSpectacol} onChange={(e) => setTrupa({...trupa, durataSpectacol: e.target.value})} />

            <label htmlFor="timpMontareDecor" className="label-update">Timp montare decor (min)</label>
            <input type="number" name="timpMontareDecor" id="timpMontareDecor" value={trupa.timpMontareDecor} onChange={(e) => setTrupa({...trupa, timpMontareDecor: e.target.value})} />

            <label htmlFor="timpDemontareDecor" className="label-update">Timp demontare decor (min)</label>
            <input type="number" name="timpDemontareDecor" id="timpDemontareDecor" value={trupa.timpDemontareDecor} onChange={(e) => setTrupa({...trupa, timpDemontareDecor: Number(e.target.value)})} />

            <label htmlFor="cerinteDecor" className="label-update">Cerinte decor</label>
            <input type="text" name="cerinteDecor" id="cerinteDecor" value={trupa.cerinteDecor} onChange={(e) => setTrupa({...trupa, cerinteDecor: e.target.value})} />

            <label htmlFor="linkFilmare" className="label-update">Link filmare</label>
            <input type="text" name="linkFilmare" id="linkFilmare" value={trupa.linkFilmare} onChange={(e) => setTrupa({...trupa, linkFilmare: e.target.value})} />

            <label htmlFor="linkScenariu" className="label-update">Link scenariu</label>
            <input type="text" name="linkScenariu" id="linkScenariu" value={trupa.linkScenariu} onChange={(e) => setTrupa({...trupa, linkScenariu: e.target.value})} />

            <label htmlFor="observatii" className="label-update">Observatii</label>
            <textarea name="observatii" id="observatii" value={trupa.observatii} onChange={(e) => setTrupa({...trupa, observatii: e.target.value})} />

            <label htmlFor="numeReprezentant" className="label-update">Nume reprezentant</label>
            <input type="text" name="numeReprezentant" id="numeReprezentant" value={trupa.numeReprezentant} onChange={(e) => setTrupa({...trupa, numeReprezentant: e.target.value})} />

            <label htmlFor="prenumeReprezentant" className="label-update">Prenume reprezentant</label>
            <input type="text" name="prenumeReprezentant" id="prenumeReprezentant" value={trupa.prenumeReprezentant} onChange={(e) => setTrupa({...trupa, prenumeReprezentant: e.target.value})} />

            <label htmlFor="numarTelefon" className="label-update">Numar telefon</label>
            <input type="text" name="numarTelefon" id="numarTelefon" value={trupa.numarTelefon} onChange={(e) => setTrupa({...trupa, numarTelefon: e.target.value})} />

            <label htmlFor="email" className="label-update">Email</label>
            <input type="text" name="email" id="email" value={trupa.email} onChange={(e) => setTrupa({...trupa, email: e.target.value})} />

            <label htmlFor="nivelStudii" className="label-update">Nivel studii</label>
            <input type="text" name="nivelStudii" id="nivelStudii" value={trupa.nivelStudii} onChange={(e) => setTrupa({...trupa, nivelStudii: e.target.value})} />

            <label htmlFor="categorie" className="label-update">Categorie</label>
            <input type="text" name="categorie" id="categorie" value={trupa.categorie} onChange={(e) => setTrupa({...trupa, categorie: e.target.value})} />

            <label htmlFor="nevoieCazare" className="label-update">Nevoie cazare</label>
            <input type="checkbox" name="nevoieCazare" id="nevoieCazare" checked={trupa.nevoieCazare} onChange={(e) => setTrupa({...trupa, nevoieCazare: e.target.checked})} />

            <label htmlFor="nevoieMancare" className="label-update">Nevoie mancare</label>
            <input type="checkbox" name="nevoieMancare" id="nevoieMancare" checked={trupa.nevoieMancare} onChange={(e) => setTrupa({...trupa, nevoieMancare: e.target.checked})} />

            <label htmlFor="nevoiSuplimentare" className="label-update">Nevoi suplimentare</label>
            <input type="text" name="nevoiSuplimentare" id="nevoiSuplimentare" value={trupa.nevoiSuplimentare} onChange={(e) => setTrupa({...trupa, nevoiSuplimentare: e.target.value})} />

            <label htmlFor="undeAuzit" className="label-update">Unde auzit</label>
            <input type="text" name="undeAuzit" id="undeAuzit" value={trupa.undeAuzit} onChange={(e) => setTrupa({...trupa, undeAuzit: e.target.value})} />

            <label htmlFor="asteptariFestival" className="label-update">Asteptari festival</label>
            <input type="text" name="asteptariFestival" id="asteptariFestival" value={trupa.asteptariFestival} onChange={(e) => setTrupa({...trupa, asteptariFestival: e.target.value})} />

            <button onClick={updateButton} className="buton">
                Update Trupa
            </button>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </div>
    )
}

export default UpdateTrupa;