import React, { useEffect, useState } from "react";
import { getNrTrupe, getNrTrupeAmatori, getNrTrupeProfesionisti } from "../Api/api";
// import { getNrTrupeAmatori, getNrTrupeProfesionisti } from "../Api/api";
import { FaTheaterMasks } from 'react-icons/fa';
import { BsStars } from 'react-icons/bs';
import { GiTrophyCup } from 'react-icons/gi';

function Dashboard() {
  const [nrTrupe, setnrTrupe] = useState();
  const [nrTrupeAmatori, setnrTrupeAmatori] = useState();
  const [nrTrupeProfesionisti, setnrTrupeProfesionisti] = useState();

  useEffect(() => {
    getNrTrupe()
      .then((response) => {
        // console.log(response)
        // console.log(response.data.count);
        if (response.status === 200)
          setnrTrupe(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  // console.log(nrTrupe);

  useEffect(() => {
    getNrTrupeAmatori()
      .then((response) => {
        // console.log(response);
        if (response.status === 200)
          setnrTrupeAmatori(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // console.log(nrTrupeAmatori);

  useEffect(() => {
    getNrTrupeProfesionisti()
      .then((response) => {
        // console.log(response);
        if (response.status === 200)
          setnrTrupeProfesionisti(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  // console.log(nrTrupeProfesionisti);

  return (
    <div className="page page-dashboard">

      <div className="body">
      <h1 className="titluDashboard">Numărul de trupe înscrise</h1>
        <div className="controllers">
          <div className="card-container">
            <h1><GiTrophyCup />
              <p>Categorie Profesioniști</p>
              <p>{nrTrupeProfesionisti}</p>
            </h1>
          </div>
          <div className="card-container">
            <h1><FaTheaterMasks />
              <p>Total Trupe</p>
              <p>{nrTrupe}</p>
            </h1>
          </div>
          <div className="card-container">
            <h1><BsStars />
              <p>Categorie Amatori</p>
              <p>{nrTrupeAmatori}</p>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;