import React, { useEffect, useState, useMemo } from "react";
import { getTrupe } from "../Api/api";
import './trupe.css';
import { useNavigate } from "react-router-dom";
import DescriereModal from "../Components/DescriereModal";
import { useModal } from "../Components/DescriereModal";
import debounce from "lodash.debounce";
import MobileGroupDetailsModal from "../Components/ModalResponsiveness";


function Trupe(props) {
    const [trupe, setTrupe] = useState([]);
    const [currentGroupList, setCurrentGroupList] = useState([])
    const [groupType, setGroupType] = useState('all')
    const [currentGroup, setCurrentGroup] = useState({})
    const [searchValue, setSearchValue] = useState("");
    const [isMobileModalOpen, setMobileModalOpen] = useState(false);

    const openMobileModal = () => {
        setMobileModalOpen(true);
    };

    const closeMobileModal = () => {
        setMobileModalOpen(false);
    };

      

    const {isOpen, onOpen, onClose} = useModal();

    const navigate = useNavigate();

    const isAdmin = props.isAdmin;
    // console.log(isAdmin)

    const getAllTrupe = () => {
        // console.log("la inceput " + new Date().getTime());
        getTrupe()
        .then((response) => {
            if (response.status === 200) {
                setTrupe(response.data);
                setCurrentGroup(response.data[0])
                //   console.log(response.data)
            }
        })
        .catch((error) => {
            console.error(error);
        });
    //   console.log("la final " + new Date().getTime());

    }

    useEffect(() => {
        
        getAllTrupe();

      }, []);
  
    useEffect(() => {
        if (groupType === 'all') {
            setCurrentGroupList(trupe)
            setCurrentGroup(trupe[0])
            return
        }
        const temp = trupe.filter((el) => el.categorie.toLowerCase() === groupType)
        setCurrentGroupList(temp)
        setCurrentGroup(temp[0])
    }, [trupe, groupType])


    const changeCurrentGroup = (id) => {
        const index = trupe.findIndex((el) => el.id === id)
        setCurrentGroup(trupe[index])
    }

    const onVideoClick = () => {
        window.open(currentGroup.linkFilmare, '_blank');
    }
    const onScenariuClick = () => {
        window.open(currentGroup.linkScenariu, '_blank');
    }
    const onDescriereClick = () => {
        onOpen()
    }

    const deleteTrupa = async (id) => {

        navigate(`/confirm/${id}`)

        
    };

    const updateTrupa = async (id) => {
        navigate(`/update/${id}`)
    }

    const onHandleSearch = (e) => {
        setSearchValue(e.target.value);
    }

    const debouncedResults = useMemo(() => {
        return debounce(onHandleSearch, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        }
    })

    useEffect(() => {
        if (searchValue !== "") {
            const trupeCopy = trupe.filter(trupa => trupa.numeTrupa.includes(searchValue));
            setTrupe(trupeCopy);
        } else {
            getAllTrupe();
        }
        
        
    }, [searchValue])
      
    

    return (
        <div className="page page-trupe">
            <h1>Trupe</h1>
            <div className={'trupe-header'}>
                <div className={' buton '} onClick={() => setGroupType('amatori')}>Amatori</div>
                <div className={' buton '} onClick={() => setGroupType('profesionisti')}>Profesioniști</div>
                <div className={' buton '} onClick={() => setGroupType('all')}>Toate trupele</div>
            </div>
            <div className={"main-container"}>
                <div className={"group-list"} >
                    <input type="text" name="search" id="search" placeholder="Cauta trupa" onChange={debouncedResults} />
                    {currentGroupList.map((el, index) => {
                        return (
<                       div className={'group-item'} onClick={() => { changeCurrentGroup(el.id);}} key={index}>                            <span>{el.numeTrupa}</span>
                            <span>{el.numePiesa}</span>
                            <div className="btn-container-trupe">
                                {
                                    isAdmin ? <button onClick={() => deleteTrupa(el.id)} className="trupe-btn">DELETE</button> : <></>
                                }
                                {
                                    isAdmin ? <button onClick={() => updateTrupa(el.id)} className="trupe-btn">Update</button> : <></>
                                }
                            </div>
                        </div>)
                    })}
                </div>
                <div className={"group-list-resp"} >
                    <input type="text" name="search" id="search" placeholder="Cauta trupa" onChange={debouncedResults} />
                    {currentGroupList.map((el, index) => {
                        return (
<                       div className={'group-item'} onClick={() => { changeCurrentGroup(el.id); openMobileModal(); }} key={index}>                            <span>{el.numeTrupa}</span>
                            <span>{el.numePiesa}</span>
                            <div className="btn-container-trupe">
                                {
                                    isAdmin ? <button onClick={() => deleteTrupa(el.id)} className="trupe-btn">DELETE</button> : <></>
                                }
                                {
                                    isAdmin ? <button onClick={() => updateTrupa(el.id)} className="trupe-btn">Update</button> : <></>
                                }
                            </div>
                        </div>)
                    })}
                </div>

                <div className={'group-details'}>
                    < div className={'container-trupa'}>
                        <h1 className={'nume-trupa'}>Trupa: {currentGroup?.numeTrupa}</h1>
                        <br />
                        <span className={'locatie'}>Localitate: <strong
                        >{currentGroup?.localitate}</strong>                            </span>
                        <span className={'studii'}>Nivel de studii: <strong>{currentGroup?.nivelStudii}</strong></span>
                        <div
                            className={`membri ${currentGroup?.nevoieCazare ? 'green' : 'red'}`}>{currentGroup?.nrMembri}</div>
                        <div className={'container-contact'}>
                        <span className={'studii'}>Nume Reprezentant: <strong>{currentGroup?.prenumeReprezentant} {currentGroup?.numeReprezentant}</strong></span>
                            {/* <h3>{currentGroup.prenumeReprezentant} {currentGroup.numeReprezentant}</h3>
                            <span className={'telefon'}>0{currentGroup.numarTlf}</span>
                            <span className={'telefon'}>{currentGroup.email}</span>  */}


                        </div>
                    </div>
                    <div className={'container-piesa'}>
                        <h1 className={'nume-piesa'}>Piesa: {currentGroup?.numePiesa}</h1>
                        <br />
                        <span className={'autor'}>Autor: <strong>{currentGroup?.autorPiesa}</strong></span>
                        <div className={'durata'}>{`${currentGroup?.durataSpectacol} min`}</div>
                        <span className={'gen'}>Gen: <strong>{currentGroup?.gen}</strong></span>
                        <span className={'regie'}>Regie: <strong>{currentGroup?.regie}</strong></span>

                    </div>
                    <div className={'container-decor'}>
                        <h3>Decor</h3>
                        <div className={'decor'}>{currentGroup?.cerinteDecor}</div>
                        <div className={'demontare'}>{currentGroup?.timpDemontareDecor} min</div>
                        <div className={'montare'}>{currentGroup?.timpMontareDecor} min</div>
                    </div>
                    <div className={'container-decor'}>
                        <h3>Observații</h3>
                        <span className={'observatii'}>{currentGroup?.observatii}</span>
                    </div>
                    <div className={'container-butoane'}>
                        <div className={'buton'} onClick={onDescriereClick}>DESCRIERE</div>
                        <div className={'buton'} onClick={onVideoClick}>VIDEO</div>
                        <div className={'buton'} onClick={onScenariuClick}>SCENARIU</div>

                    </div>


                </div>
            </div>

            <DescriereModal onClose={onClose} onOpen={onOpen} isOpen={isOpen} currentGroup={currentGroup}/>
            <MobileGroupDetailsModal isOpen={isMobileModalOpen} onClose={closeMobileModal} currentGroup={currentGroup} />
        </div>

    


    )
        ;
}

export default Trupe;

