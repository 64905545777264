import React from "react";
import logo from "./../assets/images/croco.jpg";


const NeSiscot = () => {
    return (
        <>
            <h1 className="neSiscot">
                NU ESTI SISCOT !!!!!!!!!!!!!!!!!!!!!
            </h1>
            <img src={logo} alt="croco" className="croco" width="400px"/>
        </>
        
    )
}

export default NeSiscot;
