import axios from "axios";
axios.defaults.baseURL = 'http://localhost:1235/api/';
// axios.defaults.baseURL = "https://gestapo.sisc.ro/api/";

export function login(email, password) {
    // return axios.post(
    //     "User/login",
    //     {
    //         email: email,
    //         password: password,
    //     },
    //     { "Content-Type": "application/json" }
    // );
}

export async function getTrupe(){
  return await axios.get(
        "trupe/all",
       
        { "Content-Type": "application/json" }
      );
}

export function getNrTrupe()
{
  return axios.get(
    "trupe/countAll",
       
        { "Content-Type": "application/json" }
  )
}

export function getNrTrupeAmatori()
{
  return axios.get(
    "trupe/countAmatori",
       
        { "Content-Type": "application/json" }
  )
}

export function getNrTrupeProfesionisti()
{
  return axios.get(
    "trupe/countProfesionisti",
       
        { "Content-Type": "application/json" }
  )
}

export async function deleteTrupaById(id)
{
  return await axios.delete(
    `trupe/${id}`,
    { "Content-Type": "application/json" }
  )
}

export async function updateTrupaById(id, trupa)
{
  return await axios.patch(
    `trupe/updateTrupa/${id}`,
    trupa,
    { "Content-Type": "application/json" }
  )
}

export async function getTrupaById(id)
{
  return await axios.get(
    `trupe/${id}`,
    { "Content-Type": "application/json" }
  )
}