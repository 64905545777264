import React from "react";
import '../assets/css/components/ModalResponsiveness.css'; 
import DescriereModal from "../Components/DescriereModal";


const MobileGroupDetailsModal = ({ isOpen, onClose, currentGroup, onOpen }) => {
    if (!isOpen) return null;


    const onVideoClick = () => {
        window.open(currentGroup.linkFilmare, '_blank');
    }
    const onScenariuClick = () => {
        window.open(currentGroup.linkScenariu, '_blank');
    }
    const onDescriereClick = () => {
        onOpen()
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Detalii grup</h2>
                <div className="group-details-resp">
                < div className={'container-trupa-resp'}>
                        <h1 className={'nume-trupa'}>Trupa: {currentGroup?.numeTrupa}</h1>
                        <br />
                        <span className={'locatie'}>Localitate: <strong
                        >{currentGroup?.localitate}</strong>                            </span>
                        <span className={'studii'}>Nivel de studii: <strong>{currentGroup?.nivelStudii}</strong></span>
                        <div
                            className={`membri ${currentGroup?.nevoieCazare ? 'green' : 'red'}`}>{currentGroup?.nrMembri}</div>
                        <div className={'container-contact'}>
                        <span className={'studii'}>Nume Reprezentant: <strong>{currentGroup?.prenumeReprezentant} {currentGroup?.numeReprezentant}</strong></span>
                            {/* <h3>{currentGroup.prenumeReprezentant} {currentGroup.numeReprezentant}</h3>
                            <span className={'telefon'}>0{currentGroup.numarTlf}</span>
                            <span className={'telefon'}>{currentGroup.email}</span>  */}


                        </div>
                    </div>
                    <div className={'container-piesa-resp'}>
                        <h1 className={'nume-piesa'}>Piesa: {currentGroup?.numePiesa}</h1>
                        <br />
                        <span className={'autor'}>Autor: <strong>{currentGroup?.autorPiesa}</strong></span>
                        <div className={'durata'}>{`${currentGroup?.durataSpectacol} min`}</div>
                        <span className={'gen'}>Gen: <strong>{currentGroup?.gen}</strong></span>
                        <span className={'regie'}>Regie: <strong>{currentGroup?.regie}</strong></span>

                    </div>
                    <div className={'container-decor-resp'}>
                        <h3>Decor</h3>
                        <div className={'decor'}>{currentGroup?.cerinteDecor}</div>
                        <div className={'demontare'}>{currentGroup?.timpDemontareDecor} min</div>
                        <div className={'montare'}>{currentGroup?.timpMontareDecor} min</div>
                    </div>
                    <div className={'container-decor-resp'}>
                        <h3>Observații</h3>
                        <span className={'observatii'}>{currentGroup?.observatii}</span>
                    </div>

                    <div className={'container-descriere'}>
                        <h3>Descriere</h3>
                        <p>{currentGroup.descriere}</p>
                    </div>
                    <div className={'container-butoane'}>
                        <div className={'buton'} onClick={onVideoClick}>VIDEO</div>
                        <div className={'buton'} onClick={onScenariuClick}>SCENARIU</div>

                    </div>
                </div>
                <button className="close-button" onClick={onClose}>Închide</button>
            </div>
            {/* {isOpen && <DescriereModal onClose={onClose} onOpen={onOpen} isOpen={isOpen} currentGroup={currentGroup} />}         */}
            </div>
    );
};

export default MobileGroupDetailsModal;
